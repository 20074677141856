<template>
  <section id="card-navigation">
    <b-overlay :show="show" opacity="0.95" variant="dark">

      <b-card>
        <h4></h4>

        <div class="blue box ex2">
          <div class="coral item">
            <h3 class="pink item" style="margin-top: -10px;">ข้อความจะแสดงในหน้ามีการเชื่อมต่ออินเตอร์เน็ตสำเร็จ</h3>
          </div>
          <div class="coral item">
            <!-- <span >Link 1</span> -->
            <div class="pink item" style="margin-top: -10px;">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="add_sp()">
                สร้างใหม่
              </b-button>
            </div>
          </div>
        </div>

        <b-row>
          <b-col cols="12">
            <hr />
            จำนวน {{ totalRows }} การ์ด
            <div class="custom-search d-flex justify-content-end" style="margin-top: -2em">
              <b-form-group>
                <div class="d-flex align-items-center">
                  <label class="mr-1">Search</label>
                  <b-form-input v-model="searchTerm" placeholder="Search" type="text" class="d-inline-block" />
                </div>
              </b-form-group>
            </div>
            <vue-good-table :columns="columns" :rows="rows" :rtl="direction" :search-options="{
      enabled: true,
      externalQuery: searchTerm,
    }" :pagination-options="{
      enabled: true,
      perPage: pageLength,
    }">
              <template slot="table-row" slot-scope="props">
                <div v-if="props.column.field === 'name'" @click="goto_edit(props.row.id, props.row.type_card)">
                  <u> {{ truncateString(props.row.name, 30) }}</u>
                </div>

                <span v-if="props.column.field === 'type'" @click="goto_edit(props.row.id, props.row.type_card)">{{
      props.row.type }}
                </span>
                <span v-if="props.column.field === 'viwe'" @click="goto_edit(props.row.id, props.row.type_card)">{{
      props.row.viwe }}
                </span>
                <span v-if="props.column.field === 'likes'" @click="goto_edit(props.row.id, props.row.type_card)">{{
      props.row.likes }}
                </span>
                <span v-if="props.column.field === 'status'" @click="goto_edit(props.row.id, props.row.type_card)">
                  <div align="center">
                    <p v-if="props.row.status === 'true'">กำลังใช้</p>
                    <p v-else>หมดอายุ</p>
                  </div>
                </span>

                <span v-if="props.column.field === 'date_start'" style="font-size: 14px">
                  {{ props.row.date_start }}
                  {{ props.row.time_start }} ~
                  <span v-if="props.row.day_end === 'false'">
                    {{ props.row.date_end }}
                    {{ props.row.time_end }}
                  </span>
                  <!-- <span v-else style="background-color: gold; padding: 2px; border-radius: 10px;">No End Date</span> -->
                </span>

                <span v-if="props.column.field === 'action'">
                  <span>
                    <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                      <template v-slot:button-content>
                        <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
                      </template>
                      <b-dropdown-item @click="goto_edit(props.row.id, props.row.type_card)">
                        <feather-icon icon="Edit2Icon" class="mr-50" />
                        <span>แก้ไข</span>
                      </b-dropdown-item>
                      <b-dropdown-item @click="goto_delete(props.row.id)">
                        <feather-icon icon="TrashIcon" class="mr-50" />
                        <span>ลบ</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </span>
                </span>
              </template>
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Showing 1 to </span>
                    <b-form-select v-model="pageLength" :options="['5', '10', '15', '20']" class="mx-1" @input="(value) => props.perPageChanged({ currentPerPage: value })
      " />
                    <span class="text-nowrap">
                      of {{ props.total }} entries
                    </span>
                  </div>
                  <div>
                    <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number
                      align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => props.pageChanged({ currentPage: value })
                    ">
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-col>
        </b-row>
      </b-card>
      <template v-slot:overlay>
        <div class="overlay-alert">กำลังโหลด...</div>
      </template>
    </b-overlay>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BCardTitle,
  BButton,
  BBadge,
  BPagination,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormCheckbox,
  BOverlay
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import api from "@/api";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BButton,
    BBadge,
    BPagination,
    BDropdown,
    BDropdownItem,
    VueGoodTable,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      show: false,
      dataSplashPage: null,
      totalSplashPage: null,
      totalRows: "",
      rows: [],
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: "ชื่อ",
          field: "name",
        },
        {
          label: "ประเภทการ์ด",
          field: "type",
        },

        {
          label: "อายุการใช้งาน",
          field: "date_start",
        },

        // {
        //   label: "อิมเพรสชัน",
        //   field: "viwe",
        // },
        // {
        //   label: "ความรู้สึก",
        //   field: "likes",
        // },
        {
          label: "สถานะ",
          field: "status",
        },

        {
          label: "Action",
          field: "action",
        },
      ],

      searchTerm: "",
    };
  },
  mounted() {

    this.loaddata();
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  methods: {
    async loaddata() {
      const params = {
        store_id: localStorage.getItem("store_id"),
      };
      api
        .post("get_splashpage", params, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("response.data :>> ", response.data);
          this.rows = response.data.data;
          this.totalRows = response.data.total;
        });
    },
    add_sp() {
      this.$router.push({
        path: "/create/splash",
        query: { id: 0 },
      });
    },
    truncateString(string, limit) {
      if (string.length > limit) {
        return string.substring(0, limit) + "...";
      } else {
        return string;
      }
    },
    goto_edit(id, type_card) {
      this.$router.push({
        path: "/edit/splash",
        query: {
          id: id,
          type: type_card,
        },
      });
    },
    goto_delete(id) {
      this.$swal({
        title: "คุณต้องการลบข้อมูล",
        text: "คุณจะไม่สามารถย้อนกลับได้!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          const params = {
            id: id,
          };
          api
            .post("del_splashpage", params, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
              },
            })
            .then((response) => {
              this.loaddata();
              this.$swal({
                icon: "success",
                title: "สำเร็จ ",
                text: "ข้อมูลของคุณถูกลบไปแล้ว",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            })
            .catch((error) => { });
        }
      });
    },
    updata_status(id, sp_status) {
      if (sp_status === "true") {
        sp_status = "false";
      } else {
        sp_status = "true";
      }
      const params = {
        id: id,
        status: sp_status,
      };

      api
        .post("update_status_splashpage", params, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          // console.log("response.data :>> ", response.data);
          this.loaddata();
        });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.vgt-table.bordered td {
  border-bottom-color: #ebe9f1;
  border-left-color: rgb(255, 255, 255);
  border-right-color: rgb(255, 255, 255);
}

.vgt-table.bordered th {
  border-bottom-color: #ebe9f1;
  border-left-color: rgb(255, 255, 255);
  border-right-color: rgb(255, 255, 255);
  background-color: #edeff0;
}

.ex2 {
  display: flex;
  justify-content: space-between;
}
</style>
